import React, { useRef, useEffect, useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { createWhatsAppLink } from "./WhatsappMessage";
import { ProductContext } from "../ProductContext";

const ShopByCategory = () => {
  const scrollContainerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollDuration, setScrollDuration] = useState(500);
  const { products, addToBasket } = useContext(ProductContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % products.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [products]);

  const scrollToCategory = useCallback(
    (categoryIndex) => {
      if (scrollContainerRef.current) {
        const categoryWidth = 320;
        const scrollPosition = categoryIndex * categoryWidth;

        scrollContainerRef.current.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    },
    []
  );

  useEffect(() => {
    scrollToCategory(activeIndex);
  }, [activeIndex, scrollToCategory]);

  const handleAddToBasket = (product) => {
    addToBasket(product);
  };

  return (
    <div className="flex flex-col mt-8 h-[54vh] max-h-[60vh] max-sm:h-[800px] max-sm:mt-10">
      <div
        ref={scrollContainerRef}
        className="scroll-container flex overflow-x-scroll no-scrollbar md:mx-auto gap-8 max-w-[650px] sm:max-w-full px-4"
      >
        {products.map((product, index) => (
          <CategoryCard
            key={product.id}
            product={product}
            isActive={index === activeIndex}
            onAddToBasket={handleAddToBasket}
          />
        ))}
      </div>
    </div>
  );
};

const CategoryCard = React.memo(({ product, isActive, onAddToBasket }) => {
  const [addedToBasket, setAddedToBasket] = useState(false);

  const handleAddToBasketClick = () => {
    onAddToBasket(product);
    setAddedToBasket(true);

    setTimeout(() => {
      setAddedToBasket(false);
    }, 1000);
  };

  return (
    <div
      className={`flex justify-center my-auto align-middle flex-col py-3 ${
        isActive ? "active" : ""
      }`}
    >
      <div
        className={`rounded-[22px] w-[280px] h-[350px] border-solid border-2 shadow-3xl relative ${
          isActive
            ? "border-[#3e8bbb] transition-all duration-500 ease-in-out shadow-sm"
            : ""
        }`}
      >
        <Link to={`/products/${product.id}`} className="block">
          <img
            src={product.image}
            className="w-[280px] h-[300px] object-cover rounded-[22px] shadow object-bottom"
            alt={product.name}
          />
        </Link>
        <button
          className="flex flex-row-reverse -translate-y-[35px] rounded-b-3xl justify-between w-full bg-slate-100 px-3 py-1 h-[80px] items-center shadow-inner rounded-md"
          onClick={handleAddToBasketClick}
          disabled={addedToBasket}
        >
          <span className={`text-sm capitalize bg-lavender text-gray-900 rounded-md px-4 py-2 ${addedToBasket ? 'animate-bounce' : ''}`}>
            {addedToBasket ? "Added!" : "Add to Basket"}
          </span>
          <div className="text-sm normal-font capitalize flex flex-col justify-between max-w-[130px]">
            <span className="text-left">{product.name}</span>
            <span className="number-font text-blue-600"> {product.price} LE</span>
          </div>
        </button>
      </div>
    </div>
  );
});

export default ShopByCategory;
