import { Swiper, SwiperSlide } from "swiper/react";
import { MdNoDrinks } from "react-icons/md";
import "swiper/css";
import { FaLeaf } from "react-icons/fa";
import { TbTestPipe } from "react-icons/tb";

import { createWhatsAppLink } from "./WhatsappMessage";
import { User_Results1, User_Results10, User_Results2, User_Results3, User_Results4, User_Results5, User_Results6, User_Results7, User_Results8, User_Results9 } from "../assets/images/indedx";

const Slide = ({ title, content, slideNumber, backgroundImageUrl }) => (
  <div
    className="bg-third shadow-md rounded-lg overflow-hidden bg-cover w-[250px] h-[250px] mx-auto"
    style={{ backgroundImage: `url('${backgroundImageUrl}')` }}
  >
    {/* Additional content can be added here */}
  </div>
);

const slideData = [
  
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results1,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results2,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results3,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results4,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results5,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results6,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results7,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results8,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results9,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream results",
    slideNumber: 453,
    backgroundImageUrl: User_Results10,
  },
  // Add more slide objects as needed
];

export default () => {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      breakpoints={{
        640: {
          slidesPerView: 5,
        },
        768: {
          slidesPerView: 6,
        },
      }}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      className="h-[395px] justify-center align-middle "
    >
      {slideData.map((slide, index) => (
        <SwiperSlide key={index} className="my-auto  h-[250px!important] w-[250px] rounded-lg mx-4">
          <a
            href={createWhatsAppLink(
              `I would like to ask about ${slide.content} with price 222 from site.`
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Slide {...slide} />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
