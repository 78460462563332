import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Navbar from "./utilies/Navbar";
import "./styles/fonts.css";
import Footer from "./utilies/Footer";
import Terms_condition from "./pages/Terms_condition";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy";
import Products from "./pages/Products";
import ProductDetail from "./pages/ProductDetail";
import Basket from "./pages/Basket"; // Import Basket component
import { ProductProvider } from "./ProductContext";
import { AuthProvider } from "./AuthContext";
import RegisterPage from "./pages/Register";
import LoginPage from "./pages/Login";
import AfterPayment from "./pages/AfterPayment";
import Orders from "./pages/Orders";
import trackVisit from "./utilies/tracking";
import Profile from "./pages/Profile";
import ErrorContainer from "./utilies/ErrorContainer";
import { ErrorProvider } from "./ErrorContext";
import ExactOrder from "./pages/ExactOrder";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  useEffect(() => {
    const pageUrl = window.location.href;
    trackVisit(pageUrl);
  }, []);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const pageUrl = window.location.href;
    trackVisit(pageUrl);
  }, []);

  const handleErrorClose = () => {
    setErrorMessage("");
  };
  return (
    <HelmetProvider>

    <AuthProvider>
      <ProductProvider>
        <ErrorProvider>
          <Router>
            <ErrorContainer/>

            <Navbar />
            <div className="h-[80px] bg-gradient-to-br from-lavender/65  to-lavender/20"></div>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/me" exact element={<Profile />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/:id" element={<ProductDetail />} />
              <Route path="/orders/:orderId" element={<ExactOrder />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/terms" element={<Terms_condition />} />
              <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
              <Route path="/basket" element={<Basket />} />{" "}
              <Route
                path="/payment/status/:order_id"
                element={<AfterPayment />}
              />{" "}
              <Route path="/orders" element={<Orders />} />{" "}
              {/* Add auth route */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
            </Routes>
            <Footer />
          </Router>
        </ErrorProvider>
      </ProductProvider>
    </AuthProvider>
    </HelmetProvider>

  );
}

export default App;
