import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField, Button, CircularProgress } from "@mui/material"; // Import MUI components

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false); // State for loading spinner
  const [error, setError] = useState(""); // State for error message
  const navigate = useNavigate(); // Hook for navigation

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "username") {
      newValue = value.replace(/\s/g, "_"); // Replace spaces with underscores
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading spinner
    setError(""); // Clear previous errors

    try {
      const response = await fetch("https://m0hamady.pythonanywhere.com/api/register/user/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json(); // Parse JSON response

      if (!response.ok) {
        if (data.username) {
          setError(data.username[0]); // Capture specific username error
        } else if (data.email) {
          setError(data.email[0]); // Capture specific email error
        } else {
          setError("Registration failed. Please try again.");
        }
        setLoading(false); // Stop loading spinner
        return;
      }

      // Registration successful, redirect to login page
      navigate("/login");
    } catch (error) {
      console.error("Error registering:", error.message);
      setError("An unexpected error occurred. Please try again.");
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <div className="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8 text-gray-100">
      <div className="sm:mx-auto z-30 max-sm:w-full max-sm:max-w-sm bg-primary rounded-lg text-center items-center justify-center flex py-8 w-[26%] text-white -mb-8 shadow-2xl">
        <h2 className="text-center text-2xl font-bold leading-9 w-fit tracking-tight text-gray-100">
          Register for an account
        </h2>
      </div>

      <div className="-mt-10 z-10 sm:mx-auto sm:w-full sm:max-w-sm bg-third py-4 px-4 pt-24 rounded text-white">
        <form className="space-y-6 text-white" onSubmit={handleSubmit}>
          <div>
            <TextField
              id="username"
              name="username"
              label="Username"
              value={formData.username}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              helperText="Spaces will be replaced with underscores (_)"
              error={Boolean(error)} // Mark field as error if there's an error
            />
          </div>

          <div>
            <TextField
              id="email"
              name="email"
              label="Email address"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              error={Boolean(error)} // Mark field as error if there's an error
            />
          </div>

          <div>
            <TextField
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              error={Boolean(error)} // Mark field as error if there's an error
            />
          </div>

          {/* Error message display */}
          {error && (
            <div className="text-red-500 text-sm text-center">
              {error}
            </div>
          )}

          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading} // Disable button while loading
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {loading ? "Registering..." : "Register"}
            </Button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already have an account?{" "}
          <Link to="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterPage;
